import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MyToast from '../components/MyToast';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import MySpinner from '../components/MySpinner';
import { initiateMesombPayment, savePayment, saveSubscription, updateSubscription, convertDevice, generateUniqueMongoDbID, listCoupons, sendMailWithMailJet } from '../services/api';
import InfoModal from '../components/Modal/InfoModal';
import ReactGA from 'react-ga';

var CurrencyFormat = require('react-currency-format');


const paymentOptions = {
    'ORANGE': '64d16cb1bf64e00384b76a4c',
    'MTN': '64d16cb2bf64e00384b76a4e'
}

const P_Subscription = () => {

    const location = useLocation();
    const navigate = useNavigate();
    let offer = location.state?.offer || null;


    const data = {
        phone: '',
        methode_paiement: 'ORANGE',
        country: 'Cameroun'
    }

    const [user, setUser] = React.useState(null);
    const [paymentDatas, setPaymentDatas] = React.useState(data);
    const [offerData, setOfferData] = React.useState(null);
    const [amount_cfa, setAmount_cfa] = React.useState(null);

    const [visible, setVisible] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [img, setImg] = React.useState(null);
    const [coupon, setCoupon] = React.useState(null);
    const [couponMsg, setCouponMsg] = React.useState('');
    const [couponValidity, setCouponValidity] = React.useState(false);
    const [currentCoupon, setCurrentCoupon] = React.useState(null);


    const [coupons, setCoupons] = React.useState([]);
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    const handleChange = e => {
        setPaymentDatas({ ...paymentDatas, [e.target.name]: e.target.value });
    }

    const navigateToHome = () => {
        navigate('/accueil')
    }

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setUser(authUser)
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);


    useEffect(() => {

        if (!offer) {
            navigate('/error');
            return;
        }
        else {
            let tmp = JSON.parse(offer);
            setOfferData(tmp);
        }

    }, [offer, navigate])


    useEffect(() => {
        ReactGA.pageview('/souscription');

        // Commencez à mesurer la durée de session lorsque l'utilisateur accède à la page
        ReactGA.event({
            category: 'Session',
            action: 'Start',
            label: 'souscription'
        });

        return () => {
            // Terminez la mesure de la durée de session lorsque l'utilisateur quitte la page
            ReactGA.event({
                category: 'Session',
                action: 'End',
                label: 'souscription'
            });
        }
    }, []);


    useEffect(() => {

        listCoupons({ status: 'ACTIVED' }).then(resp => {
            setCoupons(resp.data.data || []);
        })

    }, [])



    React.useEffect(() => {

        if (offerData) {
            convertDevice({
                amount: offerData.price,
                from: 'usd',
                to: 'xaf'
            }, rep_amount => {
                if (rep_amount) {
                    setAmount_cfa(rep_amount);
                }
                else {
                    setAmount_cfa(Math.round(offerData.price * 550));
                }
            })
        }

    }, [offerData]);



    React.useEffect(() => {
        setTitle('A votre attention !')
        setMsg('Les paiements en ligne ne sont actuellement disponibles qu\'au Cameroun. Si vous vous trouvez dans un autre pays et que vous souhaitez vous abonner, veuillez nous contacter via notre formulaire de contact.');
        setVisible(true);
    }, []);


    
    const handlePaySubscription = async e => {
        e.preventDefault();

        const { phone, methode_paiement, country } = paymentDatas;

        if (phone === '' || !phone || !methode_paiement || methode_paiement === '') {
            setOpenToast({
                open: true,
                msg: 'Choisir une méthode de paiement, puis entrer un numéro de téléphone valide',
                severity: 'error'
            })

            return;
        }

        if (phone.length !== 9 || phone.charAt(0) !== '6') {
            setOpenToast({
                open: true,
                msg: 'Veuillez entrer un numéro de téléphone valide !',
                severity: 'error'
            })

            return;
        }

        try {

            setinProgress(true);
            const startDate = new Date();

            const paymentID = (await generateUniqueMongoDbID()).data.data;

            //enregistrement de la souscription
            const subscription = await saveSubscription({
                offerID: offerData._id,
                userID: user.uid,
                startDate: startDate.toISOString(),
                endDate: new Date(startDate.getTime() + parseInt(offerData.duration) * 24 * 60 * 60 * 1000).toISOString(),
                subscriptionStatus: 'PENDING'
            });


            if (couponValidity === true && applyPercentage(amount_cfa) < 10) {

                //mise à jour du status de la souscription
                const subscription_ = await updateSubscription({
                    subscriptionID: subscription.data.data._id,
                    couponID: currentCoupon?._id,
                    subscriptionStatus: 'ACTIVED'
                })

                setinProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Souscription réussie !',
                    severity: 'success'
                })

                setTimeout(navigateToHome, 3500);
            }
            else {
                setTitle('Validation du paiement')
                setImg("../assets/img/payment-sms.png")
                setMsg('Pour valider le paiement veuillez suivre les instructions du SMS que vous allez recevoir sur votre mobile ');
                setVisible(true);

                const payment_ = await initiateMesombPayment(paymentID, 10, methode_paiement, phone, country);

                //enregistrement du paiement
                const payment = await savePayment({
                    paymentOptionID: paymentOptions[methode_paiement],
                    amount_dollar: applyPercentage(offerData.price),
                    subscriptionID: subscription.data.data._id,
                    paymentStatus: 'SUCCESS',
                    userID: user.uid,
                    amount_cfa: applyPercentage(amount_cfa)
                })

                //mise à jour du status de la souscription
                const subscription_ = await updateSubscription({
                    subscriptionID: subscription.data.data._id,
                    couponID: currentCoupon?._id,
                    subscriptionStatus: 'ACTIVED'
                })

                setinProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Souscription réussie !',
                    severity: 'success'
                })

                setTimeout(navigateToHome, 3500);

                sendMailWithMailJet({
                    from: 'services@exams-tcfcanada.com',
                    to_email: `examstcfcanada@gmail.com`,
                    from_name: 'Abonnement',
                    subject: 'Nouvel abonnement',
                    text: `Un nouveau abonnement à l'offre ${offerData?.name || ''} de ${offerData?.price || ''}`
                });
            }

        } catch (error) {
            console.log(error);
            setinProgress(false);
            setOpenToast({
                open: true,
                msg: 'Echec d\'activation de l\'abonnement. Veuillez vérifier votre solde et si le problème persiste réessayez ultérieurement',
                severity: 'error'
            })
        }


    }


    React.useEffect(() => {

        let exists = false;
        let coupon_tmp = null;

        for (const coup of coupons) {
            if (coup.name === coupon) {
                coupon_tmp = coup;
                exists = true;
                break;
            }
        }

        if (exists == true) {
            setCouponMsg('Coupon de réduction accepté')
            setCouponValidity(true);
            setCurrentCoupon(coupon_tmp);
        }
        else {
            setCouponMsg('Coupon invalide ou expiré')
            setCouponValidity(false);
            setCurrentCoupon(null);
        }

    }, [coupon])


    const applyPercentage = px => {

        let price_ = parseFloat(px);
        let percentage = currentCoupon?.amount || 0;
        percentage = parseInt(percentage);

        const realAmount = price_ - (price_ * percentage / 100);
        return Math.round(realAmount);
    }

    return (
        <>
            <Menu />
            <div style={{ marginTop: '12.5rem' }}></div>
            <div className='row mx-0 mt-3 justify-content-center align-items-center'>
                <div className="col-lg-5 pl-lg-2 mb-3">
                    <div className="card h-100">

                        <div className="card-header text-center">
                            <h5 className="mb-0 font-weight-black text-uppercase ">Paiement</h5>
                        </div>

                        <div className="bg-light card-body">
                            <select className="mb-3 custom-select" id="country" onChange={handleChange} name="country">
                                <option value={'Cameroun'} >Cameroun</option>
                            </select>
                            <div className="d-flex justify-content-between fs--1 mb-1">
                                <p className="mb-0">{offerData?.name}</p><span>${offerData?.price}</span>
                            </div>
                            <div className="d-flex justify-content-between fs--1 mb-1 text-success">
                                <p className="mb-0">Taxe</p><span>$0.00</span>
                            </div>
                            <hr />
                            <h5 className="d-flex justify-content-between"><span>Total</span><span>${offerData?.price}</span></h5>
                            <hr />
                            <form className='form-validation mt-2' onSubmit={handlePaySubscription}>

                                <div className='row align-items-center justify-content-center mx-0'>

                                    <h5 className='col-12 text-center'>Choisir une méthode de paiement</h5>

                                    <div className='col-12 text-center px-3 mt-4'>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-form-control-label-placement"
                                                name="methode_paiement"
                                                onChange={handleChange}
                                                defaultValue={paymentDatas.methode_paiement}
                                            >
                                                <FormControlLabel
                                                    label={
                                                        <div>
                                                            <img className="mr-3" src="../assets/img/orange_money.png" loading='lazy' width={50} alt />
                                                            <br />
                                                            <h6 className="mt-3 fs-0">Orange Mo</h6>
                                                        </div>
                                                    }
                                                    control={<Radio />}
                                                    value="ORANGE"
                                                    labelPlacement="top"
                                                />

                                                <FormControlLabel
                                                    label={
                                                        <div>
                                                            <img className="mr-3" src="../assets/img/mtn_mobile_money.png" loading='lazy' width={50} alt />
                                                            <br />
                                                            <h6 className="mt-3 fs-0">MTN Mo</h6>
                                                        </div>
                                                    }
                                                    control={<Radio />}
                                                    value="MTN"
                                                    labelPlacement="top"
                                                />

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row mx-0">
                                    <div className="col-12 px-4">
                                        <div className="form-group">
                                            <TextField
                                                label="Numéro de téléphone"
                                                id="phone"
                                                name='phone'
                                                size={'small'}
                                                onChange={handleChange}
                                                required
                                                className='mt-3'
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+237</InputAdornment>,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 px-4 mt-2">

                                        <div className="form-group">

                                            <TextField
                                                label="Coupon de réduction si vous en avez"
                                                id="couponID"
                                                name='couponID'
                                                size={'small'}
                                                onChange={e => setCoupon(e.target.value)}
                                                value={coupon}
                                                className='mt-2'
                                                fullWidth
                                            />

                                            {
                                                (coupon && coupon != "") ? (
                                                    <span className={`fs--1 ${couponValidity === true ? ' text-success' : ' text-danger'}`} style={{ fontWeight: 'bold' }}>{couponMsg}</span>
                                                ) : null
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className="text-center pt-2 px-3">
                                        <div className="fs-0 font-weight-semi-bold">Montant à payer:
                                            <span className="text-primary">
                                                <CurrencyFormat prefix=' $' value={`${applyPercentage(offerData?.price)}`} displayType={'text'} thousandSeparator={true} suffix={''} /> (<CurrencyFormat prefix='' value={`${applyPercentage(amount_cfa)}`} displayType={'text'} thousandSeparator={true} suffix={'  XAF'} />)
                                            </span>
                                        </div>
                                        <button className="btn btn-success mt-3 px-5" type="submit">Confirmer &amp; Payer</button>
                                        <p className="fs--1 mt-3 mb-0">En cliquant sur le bouton <strong>Confirmer &amp; Payer </strong> vous acceptez les <NavLink to={`/politique-confidentialite`}>Termes &amp; et Conditions</NavLink></p>
                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ marginBottom: '5rem' }}></div>


            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

            <InfoModal
                visible={visible}
                title={title}
                msg={msg}
                setVisible={setVisible}
                img={img}
            />


            <Footer />
        </>
    );
};

export default P_Subscription;